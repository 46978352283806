import $ from 'jquery'

let xhr_requests = []

const Interceptors = {
  csrf_and_disable_caching(request, next) {
    //// send Rails' CSRF Token with every requests
    if (!request.headers.has('X-CSRF-Token')) {
      request.headers.set('X-CSRF-Token', $("meta[name='csrf-token']").attr("content"))
    }
    //// disable caching for requests
    //// https://github.com/pagekit/vue-resource/issues/190
    if ( (request.method.toLowerCase() == 'get') && (request.url.indexOf('_=') == -1) ) {
      request.url += (request.url.indexOf('?') > 0 ? '&' : '?') + `_=${new Date().getTime()}`
    }
    next(response => {
      const idx = xhr_requests.findIndex(e => e === request)
      if (idx != -1) {
        xhr_requests.splice(idx, 1)
      }
    })
  },
  track_requests(request, next) {
    xhr_requests.push(request)
    next()
  },
  cancel_all_requests() {
    xhr_requests.forEach(request => { request.abort() })
  }
};

export default Interceptors;
